<template>
  <div id="app">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template>

<script>
import HelloWorld from './views/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
::-webkit-scrollbar{
  width: 0!important;
  height: 0!important;
  /* display: none; */
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  
  /* width: 100vw;
  height: 100vh; */
  
  /* margin-top: 60px; */
}
body{
  margin: 0;
  padding: 0;
}
</style>
