<template>
  <div class="page_wrap">
    <div class="magetop" id="top">
      <div class="page_top"  ref="top">
        <div class="top_menu_img">
          <img src="../assets/food_menu.png" />
        </div>

        <div class="top_menu">
          <div class="tab_view">
            <div
              ref="iWidth"
              class="menu_item"
              :class="menu_index == index ? 'menu_item_active' : ''"
              v-for="(item, index) in menuList"
              :key="index"
              @click="changeMenu(index, $event)"
            >
              {{ item }}
            </div>

            <div
              class="tab-activation-item"
              :style="{
                left: left + 'px',
                width: width + 'px',
              }"
            >
              <div class="tab_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="screen" id="screen">
      <div class="top_img" id="scrollinto_0">
        <img src="../assets/food_topbg.png" />
        <div class="top_left animate__animated animate__bounceInLeft">
          <img src="../assets/food_topLeft.png" />
        </div>
        <div class="top_art animate__animated animate__bounceInLeft">
          <img src="../assets/food_topArt.png" />
        </div>
        <div class="top_friend animate__animated animate__bounceInRight">
          <img src="../assets/food_topFriend.png" />
        </div>

        <div class="top_center animate__animated animate__bounceInDown">
          <div class="top_center_content">
            <img src="../assets/food_topCenter.png" />
          </div>

          <div
            class="top_star1 animate__animated animate__zoomOut animate__infinite infinite animate__slow"
          >
            <img src="../assets/food_topStar1.png" />
          </div>
          <div
            class="top_star2 animate__animated animate__zoomIn animate__infinite infinite animate__slow"
          >
            <img src="../assets/food_topStar2.png" />
          </div>
          <div
            class="top_star3 animate__animated animate__zoomOut animate__infinite infinite animate__slower"
          >
            <img src="../assets/food_topStar3.png" />
          </div>

          <div
            class="top_star4 animate__animated animate__zoomIn animate__infinite infinite animate__slow"
          >
            <img src="../assets/food_topStar3.png" />
          </div>
        </div>

        <div class="top_bottomLeft1 animate__animated animate__bounceInLeft">
          <img src="../assets/food_topBottomLeft1.png" />
        </div>

        <div class="top_bottomLeft2 animate__animated animate__bounceInLeft">
          <img src="../assets/food_topBottomLeft2.png" />
        </div>

        <div class="top_bottomRight1 animate__animated animate__bounceInRight">
          <img src="../assets/food_topBottomRight1.png" />
        </div>

        <div class="top_bottomRight2 animate__animated animate__bounceInRight">
          <img src="../assets/food_topBottomRight2.png" />
        </div>
      </div>

      <!--- 品牌介绍 -->
      <div class="page_introduce" id="scrollinto_1">
        <div class="introduce_top">
          <div class="introduce_top_left">
            <div class="introduce_left_title">品牌介绍</div>
            <div class="introduce_left_content">
              <div>
                美食创新、精致、文化，成就轻食产业更高的市场价值和利润。
              </div>
              <div class="span-space">
                食的作品致力于打造美味、精致、小吃文化、操作简单的轻食主义和轻食文化，使饮食更轻盈、更健康、更时尚感，深受“新生代”消费群体欢迎的饮食选择；形成以轻食文化融入和改变“新生代”生活习惯，契合“新生代”饮食时尚、精致、健康的饮食观。
              </div>
            </div>
          </div>
          <div class="introduce_top_right">
            <img
              class="introduce_right_img"
              src="../assets/food_introduce.png"
            />
          </div>
        </div>

        <div class="introduce_bottom">
          <div class="introduce_bottom_title">项目概念</div>
          <div class="introduce_bottom_content">
            <div>
              “食的作品”是食的作品 (杭州)
              餐饮管理有限公司旗下的轻食板块品牌，品牌下的产品以多种轻式美食小吃和爆款饮品为主，以年轻消费群体为需求导向而研发，以品牌连锁效应为市场运营，是受欢迎程度较大的行业和产业项目。
            </div>
            <div class="span-space">
              一经落地，广受消费者热捧，成为市场新宠。
            </div>
          </div>
        </div>
      </div>

      <div class="line1"></div>

      <!--- 项目产品 -->
      <div class="page_project" id="scrollinto_2">
        <div class="project_title">
          <img class="circle_span" src="../assets/food_titleCircle.png" />
          项目产品
        </div>
        <div class="project_subtitle">Company profile</div>

        <div class="swiper">
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            @slideChangeTransitionEnd="handleClickSlide"
            @click="show"
          >
            <swiper-slide v-for="item of foodList" :key="item.imgStr">
              <!-- <viewer> -->
              <img class="food_img" :src="item.imgStr" />
              <!-- </viewer> -->
            </swiper-slide>
          </swiper>
        </div>

        <div class="swiper-bottom">
          <!-- <el-button @click="pre" type="success" class="posr" icon="el-icon-arrow-left" circle></el-button>
        <el-button @click="next" type="success" class="posl" icon="el-icon-arrow-right" circle></el-button>-->
          <div class="posr" @click="pre">
            <img class="arrow_left_img" src="../assets/arrow_left.png" />
          </div>
          <div class="posl" @click="next">
            <img class="arrow_right_img" src="../assets/arrow_right.png" />
          </div>
          <el-carousel
            ref="cardShow"
            :autoplay="false"
            height="80px"
            arrow="always"
            indicator-position="none"
            @change="change"
          >
            <el-carousel-item v-for="item in foodList" :key="item.title">
              <h3>{{ item.title }}</h3>
              <h6>{{ item.subTitle }}</h6>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="line2"></div>

      <!--- 线下案例 -->
      <div class="page_real" id="scrollinto_3">
        <div class="project_title">
          <img class="circle_span" src="../assets/food_titleCircle.png" />
          线下案例
        </div>
        <div class="project_subtitle">Company profile</div>
        <div class="swiper-box">
          <Swiperit></Swiperit>
        </div>

        <div class="page_real_bottom">
          “食的作品”是食的作品 (杭州)
          餐饮管理有限公司旗下的轻食板块品牌，品牌下的产品以多种轻式美食小吃和爆款饮品为主，以年轻消费群体为需求导向而研发，以品牌连锁效应为市场运营，是受欢迎程度较大的行业和产业项目。
          一经落地，广受消费者热捧，成为市场新宠。
        </div>
      </div>

      <div class="line3"></div>

      <!--- 项目优势 -->
      <div class="page_advantage" id="scrollinto_4">
        <div class="project_title">
          <img class="circle_span" src="../assets/food_titleCircle.png" />
          项目优势
        </div>
        <div class="project_subtitle">Company profile</div>

        <div class="advantage_bottom">
          <div
            class="advantage_list"
            v-for="item in advantage_list"
            :key="item.title"
          >
            <div class="advantage_content">
              <el-row :gutter="50">
                <el-col :span="8">
                  <div class="advantage_left">
                    <img class="advantage__img" :src="item.imgStr" />
                  </div>
                </el-col>
                <el-col :span="16">
                  <div class="advantage_right">
                    <div class="right_top">
                      <div class="right_topCircle"></div>
                      <div class="right_topTxt">{{ item.title }}</div>
                    </div>
                    <div class="right_bottom">{{ item.content }}</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>

      <!--- 底部 -->
      <div class="page_bottom" id="scrollinto_5">
        <div class="bottom_bg">
          <div class="bottom_bgCircle"></div>
          <!-- <img  class="bottom_bgCircle" src="../assets/food_bottomCircle.png" /> -->

          <img class="bottom_bgimg" src="../assets/food_pageBottomBg.png" />
        </div>

        <div class="info">
          <img class="bottom_logo" src="../assets/food_bottom_logo.png" />
          <div class="text_font_18">杭州食的作品餐饮有限公司</div>
          <div class="text_font_18">Hangzhou food works catering Co., LTD</div>
          <div class="text_font_20">联系地址：浙江省杭州市创伟科技园A座</div>

          <div class="text_font_34">全国加盟热线：400-826-8098</div>

          <div class="line4"></div>

          <div class="text_font_28">全国诚邀合伙人</div>
          <div class="text_font_19">
            从上班族，到财富自由，只有一个食的作品的距离
          </div>
          <div class="text_font_15">
            From office workers to wealth freedom, there is only one food works
            of distance
          </div>

          <div class="qrcode">
            <el-row :gutter="45">
              <el-col :span="12">
                <div class="code_left">
                  <div class="left_code_img">
                    <img src="../assets/food_mini_program.png" />
                  </div>
                  <div class="left_txt">食的作品小程序</div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="code_right">
                  <div class="right_code_img">
                    <img src="../assets/food_kefuCode.png" />
                  </div>
                  <div class="right_txt">微信扫码联系招商经理</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="num">网站备案号：浙ICP备2023021678号-1</div>
      </div>
    </div>
    <Model v-if="bol" @close="close" @onresize="onresize"></Model>

    <!-- <div class="flex" :style='{top:top+"px"}'>
    </div> -->
  </div>
</template>

<script>
import { SwiperSlide, Swiper } from "vue-awesome-swiper";
import Swiperit from "@/components/customSwiper.vue";
import Model from "@/components/model.vue";
import "swiper/css/swiper.css";

export default {
  name: "HelloWorld",
  components: {
    Swiper,
    SwiperSlide,
    Swiperit,
    Model,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      top:'100',
      bol: false,
      menuList: [
        "首页",
        "品牌介绍",
        "项目产品",
        "线下案例",
        "项目优势",
        "招商平台",
        "线上平台",
      ],
      menu_index: 0,
      listBoxState: true,
      left: 90,
      width: 0,
      iWidths: 0,
      norWidtList: [36, 72, 72, 72, 72, 72, 72],
      activeWidthList: [60, 120, 120, 120, 120, 120, 120],
      imgList: [
        "https://wlbz.oss-cn-hangzhou.aliyuncs.com/icon/memberHome_level_discountNor.png",
        "https://wlbz.oss-cn-hangzhou.aliyuncs.com/icon/memberHome_level_discountNor.png",
        "https://wlbz.oss-cn-hangzhou.aliyuncs.com/icon/memberHome_level_discountNor.png",
        "https://wlbz.oss-cn-hangzhou.aliyuncs.com/icon/memberHome_level_discountNor.png",
        "https://wlbz.oss-cn-hangzhou.aliyuncs.com/icon/memberHome_level_discountNor.png",
        "https://wlbz.oss-cn-hangzhou.aliyuncs.com/icon/memberHome_level_discountNor.png",
      ],
      advantage_list: [
        {
          title: "品牌支持",
          content: "品牌商标授权拥有品牌经营权",
          imgStr: require("../assets/food_advantage0.png"),
        },
        {
          title: "装修指导",
          content: "专业设计师团队支持轻装修、重装饰装修成本低",
          imgStr: require("../assets/food_advantage1.png"),
        },
        {
          title: "外卖运营",
          content:
            "运营团队全程指导，提供优化页面、上传素材、产品定价、活动运营等服务",
          imgStr: require("../assets/food_advantage2.png"),
        },

        {
          title: "全套技术培训",
          content: "3-5天教你学会技术,包教包会,学会为止",
          imgStr: require("../assets/food_advantage3.png"),
        },
        {
          title: "老师上门带店",
          content:
            "开业期间总部将提供专业督导解决开店中的运营问题和前期运营全程跟踪服务",
          imgStr: require("../assets/food_advantage4.png"),
        },
        {
          title: "全套设备支持",
          content: "大到全套的全智能化机器设备小到餐具服饰等",
          imgStr: require("../assets/food_advantage5.png"),
        },
        {
          title: "提供选址",
          content: "专业选址团队协助选址",
          imgStr: require("../assets/food_advantage6.png"),
        },
        {
          title: "迭代开发",
          content: "不断更新升级产品，免费学习",
          imgStr: require("../assets/food_advantage7.png"),
        },

        {
          title: "运营支持",
          content: "节假日的营销方案、活动策划品牌升级等",
          imgStr: require("../assets/food_advantage8.png"),
        },
      ],

      foodList: [
        {
          title: "港式蛋多多爆蛋吐司",
          subTitle: "More pop egg toast",
          imgStr: require("../assets/food/food_ts_moreEgg.jpg"),
        },
        {
          title: "烤猪排芝士蛋爆蛋吐司",
          subTitle: "Pork chop cheese ",
          imgStr: require("../assets/food/food_ts_zpzsd.jpg"),
        },
        {
          title: "沙拉肥牛煎饼",
          subTitle: "Salad beef",
          imgStr: require("../assets/food/food_jb_slfn.jpg"),
        },
        {
          title: "太阳蛋嫩香肥牛爆蛋吐司",
          subTitle: "Fried egg toast",
          imgStr: require("../assets/food/food_ts_sunEgg.jpg"),
        },
       
        {
          title: "芝士培根爆蛋吐司",
          subTitle: "Cheese bacon",
          imgStr: require("../assets/food/food_ts_zspg.jpg"),
        },
        {
          title: "沙拉鸡排煎饼",
          subTitle: "Chicken chop pancakes with salad",
          imgStr: require("../assets/food/food_jb_sljp.jpg"),
        },
        {
          title: "芝士鸡排爆蛋吐司",
          subTitle: "Cheese chicken ribs",
          imgStr: require("../assets/food/food_ts_zsjp.jpg"),
        },
        {
          title: "芝士火腿爆蛋吐司",
          subTitle: "Cheese ham",
          imgStr: require("../assets/food/food_ts_zsht.jpg"),
        },
        {
          title: "爆汁烤肠煎饼",
          subTitle: "Grilled sausage",
          imgStr: require("../assets/food/food_jb_bzkc.jpg"),
        },
        {
          title: "私家巴沙鱼爆蛋吐司",
          subTitle: "Private eel",
          imgStr: require("../assets/food/food_ts_bsy.jpg"),
        },
      
        {
          title: "首尔炸鸡",
          subTitle: "Seoul fried chicken",
          imgStr: require("../assets/food/food_xc_srzj.jpg"),
        },
        {
          title: "爆蛋吐司+小吃+咖啡/饮料自选",
          subTitle: "Fried egg toast + Snack + Coffee/drink of your choice",
          imgStr: require("../assets/food/food_tc_tsCoffeXc.jpg"),
        },
        {
          title: "香辣鸡翅",
          subTitle: "Seoul fried chicken",
          imgStr: require("../assets/food/food_xc_xljc.jpg"),
        },
        {
          title: "爆款DIY爆蛋吐司",
          subTitle: "DIY fried egg toast",
          imgStr: require("../assets/food/food_diyCoffe.jpg"),
        },
      
      ],

      swiperOption: {
        direction: "horizontal", // 滑动的方向(水平方向)
        loop: true, // 是否循环
        autoplay: {
          delay: 4000, // 自动播放的时长
        },
        clickable: true, //允许分页点击跳转
        slidesPerView: 5, // 设置可视区一共显示几张图片,设置auto,设置auto为自适应;
        centeredSlides: true, // 设置为true时,带有active-slide类名的图片会居中
        spaceBetween: 4, //  每张图片之间的间隔

        // on: {
        //   click: (v) => {
        //     console.log("this===", this.$refs.mySwiper.$swiper);
        //     console.log(
        //       "clickedIndex===",
        //       this.$refs.mySwiper.$swiper.clickedIndex
        //     );

        //     let index = this.$refs.mySwiper.$swiper.clickedIndex - 5;

        //     if (index == 8) {
        //       index = 0;
        //     }
        //     console.log("index===", index);
        //     this.$refs.cardShow.setActiveItem(index);
        //   },
        // },
      },
    };
  },
  watch: {
    // menu_index:{
    //   handler(newVal){
    //      this.$nextTick(() => {
    //     this.tabLineChange();
    //    });

    //   },
    //   immediate:true
    // }
    menu_index(newVal) {
      this.menu_index = newVal;
      this.$nextTick(() => {
        this.tabLineChange();
      });
    },
  },

  mounted() {
    this.tabLineChange();
    let timeId;
    window.addEventListener(
      "scroll",
      () => {
        // 页面滚动停止100毫秒后才会执行下面的函数。
        clearTimeout(timeId);
        timeId = setTimeout(() => {
          this.scrollToTop();
        }, 100);

      },
      true
    );

    this.onresize();
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },

  methods: {
    close() {
      this.bol = false;
      this.onresize();
    },
    onresize() {
      var browerWidth = window.innerWidth; //浏览器可视宽度
      var baseWidth = 1920; //设计稿宽度
      var zoomValue = browerWidth / baseWidth; //缩放比例计算
      document.getElementById("screen").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
        document.getElementById("top").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
      window.onresize = function () {
        //窗口尺寸变化时，重新计算和缩放
        browerWidth = window.innerWidth;
        zoomValue = browerWidth / baseWidth;
        document.getElementById("screen").style.transform =
          "scale(" + zoomValue + "," + zoomValue + ")";
          document.getElementById("top").style.transform =
          "scale(" + zoomValue + "," + zoomValue + ")";
      };
    },
    pre() {
      var data = this.$refs.mySwiper.$swiper;
      this.$refs.cardShow.prev();
      data.slidePrev(50, false);
    },
    next() {
      var data = this.$refs.mySwiper.$swiper;
      this.$refs.cardShow.next();
      data.slideNext(50, false);
    },
    handleClickSlide() {
      var data = this.$refs.mySwiper.$swiper.realIndex;
      this.$refs.cardShow.setActiveItem(data);
    },
    show(e) {
      this.$viewerApi({
        images: [e.target.currentSrc],
      });
    },
    change(e) {},

    tabLineChange() {
      let data = document.getElementsByClassName("menu_item");

      let activeData = document.getElementsByClassName("menu_item_active");
      let space = 90;

      this.width = this.activeWidthList[this.menu_index];
      space = this.menu_index * 90 + 90;
      this.left =
        this.norWidtList.slice(0, this.menu_index).reduce((num, item) => {
          return num + item;
        }, 0) + space;
    },

    changeMenu(index, e) {
      if (index == 6) {
        this.bol = true;
        return;
      }
      if (index != this.menu_index) {
        this.menu_index = index;
      }

      let idS = "scrollinto_" + index;
      document.getElementById(idS).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "center", // 上边框与视窗顶部平齐。默认值
      });
      this.listBoxState = false; //在data中定义一个初始化值true

      let timeId;
      clearTimeout(timeId);
      timeId = setTimeout(() => {
        this.listBoxState = true;
      }, 1000);
    },

    //滚动右侧事件
    scrollToTop() {
      var domHight = document.body.offsetHeight;
      let d = document.documentElement.scrollTop
      this.top='150'

      if (d==0) {
        this.top='100'
      }

      // dom滚动位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (this.listBoxState) {
        this.menuList.forEach((v, i) => {
          if (i == 6) return;
          let idS = "scrollinto_" + i;
          // 获取监听元素距离视窗顶部距离
          var offsetTop = document.getElementById(idS).offsetTop;
          // 获取监听元素本身高度
          var scrollHeight = document.getElementById(idS).scrollHeight;

          // 如果 dom滚动位置 >= 元素距离视窗距离 && dom滚动位置 <= 元素距离视窗距离+元素本身高度
          // 则表示页面已经滚动到可视区了。
          if (scrollTop >= offsetTop && scrollTop <= offsetTop + scrollHeight) {
            // 导航栏背景色选中
            this.menu_index = i;
          }
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/.el-button--success {
  background-color: rgba(31, 45, 61, 0.11);
  color: #fff;
  border-color: rgba(31, 45, 61, 0.11);
}
/deep/.el-carousel__arrow {
  display: none;
}
.page_wrap {
  overflow: auto;
  // min-width: 1700px;
  // max-width: 1921px;
  // width: 100%;
  height: 100vh;
  width: 100vw;
  .screen {
    display: inline-block;
    width: 1920px;
    // height: 6900px;
    transform-origin: 0 0;
    position: absolute;
    left: 0;
  }
}
.magetop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 1920px;
  transform-origin: 0 0;
  .page_top {
    width: 100%;
    height: 100px;
    z-index: 100;
    background: #61baba;
    overflow: hidden;
    // opacity: 1;
  }
}

.top_menu_img {
  position: sticky;
  top: 30px;
  left: 3%;
  z-index: 100;
  float: left;
  img {
    width: 112px;
    height: 42px;
  }
}

.top_menu {
  position: sticky;
  right: 10%;
  top: 10px;
  float: right;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  z-index: 100;
}

.menu_item {
  margin-left: 90px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  transition: all 0.3s;
  cursor: pointer;
}

.menu_item_active {
  margin-left: 90px;
  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
}

// .menu_item_active::after {
//   content: "";
//   width: 36px;
//   height: 1px;
//   display: block;
//   margin: 0 auto;
//   border-bottom: 2px solid #fff;
//   padding: 1px;
// }
.top_img {
  height: 982px;
  width: 100%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.top_left {
  position: absolute;
  top: 176px;
  left: 211px;
  width: 235px;
  height: 164px;
}
.top_art {
  position: absolute;
  top: 316px;
  left: 302px;
  right: 366px;
  height: 101px;
}
.top_friend {
  position: absolute;
  top: 261px;
  left: 323px;
  right: 383px;
  height: 387px;
  img {
    width: 100%;
    height: 100%;
  }
}
.top_center {
  position: absolute;
  top: 253px;
  left: calc(50% - 352px);
  // transform: translateX(-50%);
  width: 704px;
  height: 463px;
}
.top_center_content {
  position: relative;
}

.top_bottomLeft1 {
  position: absolute;
  bottom: 0;
  left: 156px;
  width: 234px;
  height: 310px;
}
.top_bottomLeft2 {
  position: absolute;
  bottom: 25px;
  left: 375px;
  width: 223px;
  height: 289px;
}
.top_bottomRight1 {
  position: absolute;
  bottom: 37px;
  right: 333px;
  width: 656px;
  height: 208px;
}
.top_bottomRight2 {
  position: absolute;
  bottom: 71px;
  right: 210px;
  width: 235px;
  height: 163px;
}
.top_star1 {
  position: absolute;
  left: -49px;
  top: 100px;
  width: 34px;
  height: 43px;
}
.top_star2 {
  position: absolute;
  right: 160px;
  top: -60px;
  width: 54px;
  height: 67px;
}
.top_star3 {
  position: absolute;
  right: -57px;
  top: 260px;
  width: 26px;
  height: 33px;
}
.top_star4 {
  position: absolute;
  left: 140px;
  top: -20px;
  width: 26px;
  height: 33px;
}

.page_introduce {
  // margin: 0 312px 0 380px;

  width: 1158px;
  margin: auto;

  padding-top: 120px;
}
.introduce_top {
  display: flex;
}
.introduce_top_left {
  width: 50%;
}

.introduce_left_title {
  width: 220px;
  height: 47px;
  background: #64b6b6;
  border-radius: 0px 24px 24px 26px;
  font-size: 27px;
  font-weight: 400;
  color: #ffffff;
  line-height: 47px;
  opacity: 0.9;
}
.introduce_left_content {
  width: 100%;
  // width: 510px;
  height: 156px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  text-align: left;
  padding-top: 58px;
}
.span-space {
  margin-top: 30px;
}
.introduce_top_right {
  width: 50%;
}
.introduce_right_img {
  // padding-left: 200px;
  width: 510px;
  height: 307px;
}
.introduce_bottom {
  margin-top: 55px;
  text-align: left;
}

.introduce_bottom_title {
  width: 220px;
  height: 47px;
  background: #64b6b6;
  border-radius: 0px 24px 24px 26px;
  font-size: 27px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 47px;
  opacity: 0.9;
  text-align: center;
}
.introduce_bottom_content {
  margin-top: 58px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.page_project {
  padding-top: 212px;
}

.project_title {
  height: 26px;
  font-size: 26px;
  font-weight: bold;
  color: #64b6b6;
  text-align: center;
  position: relative;
  .circle_span {
    position: absolute;
    top: -20px;
    left: calc(50% - 80px);
    width: 54px;
    height: 54px;
  }
}
.project_subtitle {
  margin-top: 10px;
  height: 23px;
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  text-align: center;
}

.swiper {
  width: 1600px;
  margin: auto;
  margin-top: 108px;
  margin-bottom: 50px;
  // margin: 108px 115px 50px;
  // height: 541px;
}

// .swiper-container {
//   width: 100%;
//   height: 100%;
// }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.85);
  // width: 20% !important;
  width: 317px !important;
  height: 425px;

  box-sizing: border-box;
  border: 4px solid #61baba;
  border-radius: 21px;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
  transform: scale(1);
  border: 4px solid #f6b231;
  border-radius: 21px;
}
.food_img {
  width: 100%;
  height: 417px;
  //height: 100%;
  margin: auto;
  // margin-top: 1px;
  // margin-left: 1px;
  border-radius: 17px;
}
.slide-img {
  width: 100%;
  height: 100%;
}
.swiper-bottom {
  position: relative;
  width: 600px;
  height: 80px;
  margin: 0 auto;

  .posr {
    position: absolute;
    left: -5%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
  }
  .posl {
    position: absolute;
    right: -5%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
  }
}

.arrow_left_img {
  width: 68px;
  height: 68px;
}
.arrow_right_img {
  width: 68px;
  height: 68px;
}
.line1 {
  margin: 88px 360px;
  height: 1px;
  background: #ededed;
}
.line2 {
  margin: 150px 360px 0;
  height: 1px;
  background: #ededed;
}
.swiper-box {
  width: 1200px;
  height: 347px;
  margin: auto;
  height: 347rpx;
}

.line3 {
  margin: 150px 360px;
  height: 1px;
  background: #ededed;
}
.page_real {
  padding-top: 150px;
}
.page_real_bottom {
  // margin: 115px 573px 0;
  width: 772px;
  margin: auto;
  margin-top: 115px;

  font-size: 12px;
  font-weight: 500;
  color: #333333;
  text-align: center;
}
.page_advantage {
  padding-top: 130px;
}
.advantage_bottom {
  margin: 160px 300px 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.advantage_list {
  display: inline-block;
  width: 33%;
  margin-bottom: 30px;
  text-align: center;
}
.advantage_content {
  margin-right: 30px;
  // display: flex;
  // width: 90%;
  // height: 100%;

  background-color: #ffffff;
  border-radius: 10rpx;
}
.advantage_left {
  width: 120px;
  height: 120px;

  img {
    width: 100%;
    height: 100%;
  }
}

.right_top {
  padding-top: 20px;

  position: relative;
  .right_topCircle {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 86px;
    height: 22px;
    background: #62afaf;
    opacity: 0.2;
    border-radius: 11px;
  }
}
.right_topbg {
  position: absolute;
  top: 10px;
  left: 5px;
  width: 86px;
  height: 22px;
  background: #62afaf;
  opacity: 0.2;
  border-radius: 11px;
}
.right_topTxt {
  height: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  text-align: left;
}
.right_bottom {
  margin-top: 22px;
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: left;
}
.page_bottom {
  background: #64b6b6;
  .bottom_bg {
    padding-top: 200px;
    width: 100%;
    height: 819px;
    background: #ffff;
    position: relative;

    .bottom_bgCircle {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -1px;
      height: 550px;
      width: 100%;
      background: url("../assets/food_bottomCircle.png");
      background-size: 100% 230%;
    }

    .bottom_bgimg {
      width: 100%;
      height: 619px;
    }
  }
}
.info {
  background: #64b6b6;

  .bottom_logo {
    width: 347px;
    height: 129px;
    margin: 0px auto 50px;
  }
}
.text_font_18 {
  margin-top: 8px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.text_font_20 {
  margin-top: 8px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.text_font_34 {
  margin-top: 39px;
  font-size: 34px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.line4 {
  margin: 39px 534px;
  height: 1px;
  background: #ffffff;
}
.text_font_28 {
  text-align: center;
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.text_font_19 {
  margin-top: 30px;
  font-size: 19px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.text_font_15 {
  margin-top: 14px;
  font-size: 15px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.qrcode {
  margin: 64px auto;
  width: 327px;
  height: 180px;
}
.left_code_img {
  width: 141px;
  height: 141px;
  border-radius: 17px;
  img {
    width: 100%;
    height: 100%;
  }
}
.right_code_img {
  width: 141px;
  height: 141px;
  background: #ffffff;
  border-radius: 17px;
  img {
    width: 100%;
    height: 100%;
  }
}
.left_txt {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.right_txt {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.num {
  margin-top: 178px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  padding: 16px 0;
  background: #4e9696;
}

h3 {
  color: #f6b231;
}
h6 {
  margin-top: -15px;
  color: #f6b231;
}

.tab-activation-item {
  position: absolute;
  height: 30px;
  // width: 36px;
  top: 54px;
  left: 0;
  z-index: 9;
  transition: all 0.3s linear;
  border-radius: 3px;
}
.tab_line {
  width: 36px;
  height: 5px;
  background: #ffffff;
  border-radius: 3px;
  margin: 10px auto 0;
}
.tab_view {
  min-width: 100%;
  height: 80px;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.flex{
  background-color: red;
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  z-index: 999999;
  transition: 0.8s;
}
</style>
