<template>
  <div class="home">
    <div class="box"></div>
    <div class="back el-icon-close" @click="close"></div>
    <div class="content" id="content">
      <div>
        <div>
          <viewer>
            <img
              class="img animate__animated animate__backInDown"
              src=".././assets/xcx.png"
            />

            <img
              class="img animate__animated animate__backInUp"
              style="margin-left: 30px"
              src=".././assets/3.png"
            />
          </viewer>
        </div>
        <p class="font">食的作品小程序</p>
      </div>
      <div class="tow">
        <div>
          <img
            class="icon animate__animated animate__backInDown"
            src=".././assets/icon.png"
          />
          <p class="font">食的作品小程序</p>
        </div>
        <div class="bottom">
          <div>
            <img
              class="icon animate__animated animate__backInLeft"
              src=".././assets/mt.png"
            />
            <p class="font">食的作品美团门店</p>
          </div>
          <div>
            <img
              class="icon animate__animated animate__backInRight"
              src=".././assets/elm.png"
            />
            <p class="font">食的作品饿了么门店</p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <viewer>
            <img
              class="img animate__animated animate__backInDown"
              src=".././assets/2.png"
            />
            <img
              class="img animate__animated animate__backInUp"
              style="margin-left: 30px"
              src=".././assets/1.png"
            />
          </viewer>
        </div>
        <p class="font">食的作品美团饿了么门店</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      zoomValue: 0,
    };
  },
  created() {},
  watch: {
    zoomValue(newval, oldval) {
      this.$emit("onresize")
    },
  },
  mounted() {
    var that = this;
    var browerWidth = window.innerWidth; //浏览器可视宽度
    var baseWidth = 1920; //设计稿宽度
    var zoomValue = browerWidth / baseWidth; //缩放比例计算
    document.getElementById("content").style.transform =
      "scale(" + zoomValue + "," + zoomValue + ")"; //mainContainer为主容器id
    window.onresize = function () {
      //窗口尺寸变化时，重新计算和缩放
      browerWidth = window.innerWidth;
      zoomValue = browerWidth / baseWidth;
      that.setval();
      document.getElementById("content").style.transform =
        "scale(" + zoomValue + "," + zoomValue + ")";
    };
  },
  methods: {
    setval() {
      this.zoomValue = Math.random();
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped lang="less">
.screen {
  display: inline-block;
  width: 1920px;
  height: 6900px;
  transform-origin: 0 0;
  position: absolute;
  left: 0;
}
.box {
  background-color: #000;
  opacity: 0.8;
  position: absolute;
  width: 101%;
  height: 6900px;
  top: 0;
  left: 0;
  z-index: 999;
}
.back {
  top: 5%;
  right: 5%;
  position: fixed;
  color: #ffffff;
  z-index: 99999;
  cursor: pointer;
  font-size: 50px;
}
.content {
  width: 1550px;
  height: 80%;
  position: fixed;
  top: 10%;
  left: 10%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform-origin: 0 0;
  .img {
    width: 213px;
    height: 433px;
  }
  .icon {
    width: 107px;
    height: 107px;
  }
  .font {
    color: #ffffff;
    text-align: center;
  }
  .tow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .bottom {
      width: 400px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
/deep/.viewer-canvas > img {
  z-index: 99999999;
}
</style>
