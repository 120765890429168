<template>
  <div class="box">
    <!-- <el-button @click="pre" type="success" class="posr" icon="el-icon-arrow-left" circle></el-button>
    <el-button @click="next" type="success" class="posl" icon="el-icon-arrow-right" circle></el-button> -->
    <div class="posr" @click="pre">
      <img class="arrow_left_img" src="../assets/arrow_left.png" />
    </div>
    <div class="posl" @click="next">
      <img class="arrow_right_img" src="../assets/arrow_right.png" />
    </div>
    <swiper :options="swiperOption" ref="mySwiper" @click="handleClickSlide">
      <swiper-slide v-for="item of storeList" :key="item.imgStr">
        <div class="store_content">
          <!-- <viewer :images="images"> -->
          <img @click="show(item)" class="store_img" :src="item.imgStr" />
          <!-- </viewer> -->

          <div class="store_info">
            <div class="store_name">{{ item.storeInfo.storeName }}</div>
            <div class="span_txt">
              食的作品<span>{{ item.storeInfo.storeSubName }}</span>
            </div>
            <div class="span_txt">
              客流量:
              <span>{{ item.storeInfo.num }}</span>
            </div>
            <div class="span_txt">
              开店日期:
              <span>{{ item.storeInfo.time }}</span>
            </div>
            <div class="span_txt">
              店铺地址
              <span>{{ item.storeInfo.address }}</span>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { SwiperSlide, Swiper } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        direction: "horizontal", // 滑动的方向(水平方向)
        loop: true, // 是否循环
        autoplay: {
          delay: 4000, // 自动播放的时长
        },
        clickable: true, //允许分页点击跳转
        slidesPerView: 5, // 设置可视区一共显示几张图片,设置auto,设置auto为自适应;
        centeredSlides: true, // 设置为true时,带有active-slide类名的图片会居中
        spaceBetween: 4, //  每张图片之间的间隔
      },
      storeList: [
        {
          imgStr: require("../assets/store/store0.jpg"),
          index: 0,
          storeInfo: {
            storeName: "杭州滨江店",
            storeSubName: "聚才路店铺",
            num: "9253/月",
            time: "2023.7.2",
            address: "浙江省杭州市滨江区长河街道滨汇之都地下一层商铺 B1055",
          },
        },
        {
          imgStr: require("../assets/store/store1.jpg"),
          index: 1,
          storeInfo: {
            storeName: "杭州上城区店",
            storeSubName: "三花国际店铺",
            num: "9215/月",
            time: "2023.5.1",
            address: "浙江省杭州市上城区明月桥路198号三花国际大厦负一层B-7",
          },
        },
        {
          imgStr: require("../assets/store/store2.jpg"),
          index: 2,
          storeInfo: {
            storeName: "海南三亚店",
            storeSubName: "三亚店铺",
            num: "8511/月",
            time: "2023.7.16",
            address: "海南省三亚市崖州区崖州保港路乾隆村二小组250号一层铺面",
          },
        },
        {
          imgStr: require("../assets/store/store3.jpg"),
          index: 3,
          storeInfo: {
            storeName: "杭州萧山店",
            storeSubName: "圣奥科创园店铺",
            num: "9125/月",
            time: "2023.3.1",
            address: "浙江省杭州市萧山区知行路1666号4幢",
          },
        },
        {
          imgStr: require("../assets/store/store4.jpg"),
          index: 4,
          storeInfo: {
            storeName: "杭州西溪银泰店",
            storeSubName: "西溪店铺",
            num: "9860/月",
            time: "2023.7.2",
            address: "浙江省杭州市西湖区蒋村街道西溪银泰商业中心2号楼东楼113室",
          },
        },
        {
          imgStr: require("../assets/store/store5.jpg"),
          index: 5,
          storeInfo: {
            storeName: "江苏无锡店",
            storeSubName: "无锡店铺",
            num: "8462/月",
            time: "2023.5.22",
            address: "江苏省无锡市惠山区海瑞路12-10号",
          },
        },
      ],
      images: [
        require("../assets/store/store0.jpg"),
        require("../assets/store/store1.jpg"),
        require("../assets/store/store2.jpg"),
        require("../assets/store/store3.jpg"),
        require("../assets/store/store4.jpg"),
        require("../assets/store/store5.jpg"),
      ],
    };
  },
  created() {},
  mounted() {},
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    handleClickSlide(e, i) {
      console.log(e.target.parentElement.firstElementChild.innerHTML, i, 12313);
      if (e.target.parentElement.firstElementChild.innerHTML) {
        this.storeList.forEach((i) => {
          if (
            i.storeInfo.storeName ==
            e.target.parentElement.firstElementChild.innerHTML
          ) {
            this.$viewerApi({
              images: [i.imgStr],
            });
          }
        });
      } else {
        this.$viewerApi({
          images: [e.target.currentSrc],
        });
      }
      // target.parentElement.innerText

      // console.log(e,this.$refs.mySwiper.$swiper.realIndex);

      // var data = this.$refs.mySwiper.$swiper.realIndex;
      // console.log(data, 112313);
    },
    pre() {
      var data = this.$refs.mySwiper.$swiper;
      data.slidePrev(100, false);
    },
    next() {
      var data = this.$refs.mySwiper.$swiper;
      data.slideNext(100, false);
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.swiper {
  margin: 108px 115px 50px;
  height: 501px;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: 347px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}
.store_img {
  width: 250px;
  height: 347px;
}

.box {
  position: relative;
  .posr {
    position: absolute;
    left: -10%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
  }
  .posl {
    position: absolute;
    right: -10%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 99;
  }
}
.arrow_left_img {
  width: 68px;
  height: 68px;
}
.arrow_right_img {
  width: 68px;
  height: 68px;
}
.store_content {
  position: relative;
}
.store_content:hover .store_info {
  opacity: 1;
  height: 60%;
}
.store_info {
  position: absolute;
  bottom: 5px;
  left: 0px;
  right: 0;
  width: 250px;
  height: 0;
  transition: all 0.3s linear;
  background: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  opacity: 0;
  color: #333;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: hidden;
}
.store_name {
  font-size: 22px;
  font-weight: 400;
  color: #fff;
  margin: 20px auto;
}
.span_txt {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-top: 5px;
}
</style>
